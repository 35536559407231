<template>
  <div class="Homepage" style="background: #F4F4F4; height: 100%;">

    <div class="top-menu">
      <div class="top-content">
        <div class="top-content-left" @click="gotoCompany">
          <img src="../assets/images/top_left_icon_01.png" style="width: 18px; height: 18px;">
        </div>
        <div class="div_product_solra" :style="divStyle1" @click="handleTopMenuClick(1)">Solra</div>
        <div class="div_product_tm" :style="divStyle2" @click="handleTopMenuClick(2)">TM</div>
      </div>
      <div class="top-content-logo"><img src="../assets/images/logo.png" alt="Icon"></div>

      <div class="top-content-right" @click="callPhone">
        <img class="right-icon" src="../assets/images/call_phone.png">
        <span class="right-text">联系我们</span>
      </div>
    </div>

    <div class="middle-content">
      <div v-if="currentTopMenu == 1">
        <div class="product_1">
          <div class="middle-content-right">
            <div class="middle-content-right-big">
              <img src="../assets/images/product_1.png" style="margin-left: 40px; width: 630px; height: 400px; /*margin-top: 117px;*/">
            </div>
            <div class="middle-content-right-small">
              <img src="../assets/images/product_small_1.png" style="width: 160px; height: 124px;">
              <img src="../assets/images/product_small_2.png" style="width: 160px; height: 124px; margin-left: 18px;">
              <img src="../assets/images/product_small_3.png" style="width: 160px; height: 124px; margin-left: 18px;">
            </div>
          </div>
          <div class="middle-content-left">
          <span class="middle-content-left-title">
            环球·智净SolarBot
          </span>
            <span class="middle-content-left-desc">
            太阳能光伏板干洗革命者
          </span>
            <span class="middle-content-left-content">
            搭载高精度GPS与AI视觉识别系统，SolarBot能够自主规划最优清洗路径， 实现全覆盖无遗漏的清洗作业。
          </span>
            <div class="middle-content-left-more-btn"
                 @click="gotoDetail"
                 @mouseover="onMouseOver"
                 @mouseleave="onMouseLeave">
              <img :src="btnDetailBackgroundImage">
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="currentTopMenu == 2">
        <span>
          TM
        </span>
      </div>
    </div>

    <div class="bottom-bar">
      <div class="bottom-line"></div>
      <div class="bottom-bar-content">
        <div class="bottom-copyright">
          Copyright ©2024 Global Intelligent Manufacturing (Tianjin) Technology Co., Ltd.
        </div>
        <div class="beian_class">津ICP备2024022977号</div>
        <div class="bottom-right-call" @click="callPhone">
          <div class="bottom-right-phone" style="display: flex;align-items: center;">
            <span class="bottom-right-phone-text">联系我们</span>
            <img class="bottom-right-phone-icon" src="../assets/images/call_phone.png">
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import detail_btn from '@/assets/images/product_more_detail_btn.png';
import detail_btn_over from '@/assets/images/product_more_detail_btn_over.png';
export default {
  name: "HomePage",
  data() {
    return {
      currentTopMenu: 1,
      divStyle1Flag: true,
      divStyle2Flag: false,
      btnDetailBackgroundImage: detail_btn,
    };
  },
  computed: {
    divStyle1() {
      return {
        background: this.divStyle1Flag ? '#000000' : '#FFFFFF',
        borderRadius: 30,
        color: this.divStyle1Flag ? '#FFFFFF': '#000000',
      };
    },
    divStyle2() {
      return {
        background: this.divStyle2Flag ? '#000000' : '#FFFFFF',
        color: this.divStyle2Flag ? '#FFFFFF': '#000000',
      };
    },
  },
  mounted() {
    // 禁止滑动
    // this.disableScroll();
  },
  beforeDestroy() {
    // 启用滑动
    this.enableScroll();
  },
  methods: {
    handleTopMenuClick(type) {
      console.log('handleTopMenuClick currentTopMenu:'+this.currentTopMenu + ', type:'+type);
      if (this.currentTopMenu != type) {
         this.currentTopMenu = type;
         if (type == 1) { // 第一个被选中
            this.divStyle1Flag = true;
            this.divStyle2Flag = false;
         } else if(type == 2) { // 第二个被选中
            this.divStyle2Flag = true;
            this.divStyle1Flag = false;
         }
      }
    },
    callPhone() {
      console.log('callPhone...');
      alert("请打电话联系我们: 110");
    },
    gotoCompany() {
      console.log("go to company.");
      this.$router.push({ name: 'company' });
    },
    onMouseOver() {
      this.btnDetailBackgroundImage = detail_btn_over;
    },
    onMouseLeave() {
      this.btnDetailBackgroundImage = detail_btn;
    },
    disableScroll() {
      document.body.style.overflow = 'hidden';
      document.addEventListener('touchmove', this.preventScroll, { passive: false });
    },
    enableScroll() {
      document.body.style.overflow = '';
      document.removeEventListener('touchmove', this.preventScroll, { passive: false });
    },
    preventScroll(event) {
      event.preventDefault();
    },
    gotoDetail() {
      console.log("currentTopMenu:" + this.currentTopMenu);
      this.$router.push({ name: 'detail' });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-menu {
  display: flex;
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  background: #FFFFFF;
  border-radius: 50px;
  height: 50px;
  align-items: center;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.top-content {
  display: flex;
  justify-content: space-around;
}
.top-content-left {
  display: flex;
  align-content: center;
  align-items: center;
  margin-left: 13px;
}
.div_product_solra {
  margin-left: 60px;
  margin-top: 6px;
  width: 50px;
  height: 100%;
  border-radius: 30px;

  text-align: center;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;

  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 300;
  font-size: 14px;
  font-style: normal;
}
.div_product_tm {
  margin-left: 40px;
  margin-top: 6px;
  width: 50px;
  height: 100%;
  border-radius: 30px;

  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 300;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  align-items: center;
  font-style: normal;
  padding-top: 2px;
  padding-bottom: 2px;
}
.top-content-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70%;
}
.top-content-right {
  margin-left: auto;
  margin-right: 26px;
  display: flex;
  align-items: center;
}
.right-icon {
  margin-right: 3px;
}
.right-text {
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 300;
  font-size: 14px;
  color: #000000;
  text-align: left;
  font-style: normal;
}
.middle-content {
  background: #F4F4F4;
  /*padding-bottom: 100px;*/
  padding-top: 20px;
}
.bottom-bar-content {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  background: #F4F4F4;
}
.bottom-copyright {
  display: flex;
  align-content: flex-start;
  margin-left: 39px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 300;
  font-size: 14px;
  color: #7F7F7F;
  line-height: 36px;
  text-align: left;
  font-style: normal;
  height: 70px;
  align-items: center;
}
.beian_class {
  display: flex;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 300;
  font-size: 14px;
  color: #7F7F7F;
  line-height: 36px;
  font-style: normal;
  height: 70px;
  align-items: center;
  margin-left: 60px;
}
.bottom-right-call {
  height: 70px;
  display: flex;
  margin-left: auto;
  margin-right: 26px;
  align-items: center;
}
.bottom-right-phone-text {
  margin-right: 20px;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 300;
  font-size: 14px;
  color: #7F7F7F;
  line-height: 36px;
  text-align: left;
  font-style: normal;
}
.bottom-line {
  float: right;
  width: 100%;
  height: 1px;
  background: #D8D8D8;
}

.middle-content-left {
  margin-left: 80px;
  /*margin-top: 65px;*/
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1; /* 设置z-index高于背景 */
}
.middle-content-left-title {
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 60px;
  color: #000000;
  line-height: 90px;
  text-align: left;
  font-style: normal;
}
.middle-content-left-desc {
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 300;
  font-size: 30px;
  color: #000000;
  line-height: 45px;
  text-align: left;
  font-style: normal;

  margin-top: 32px;
}
.middle-content-left-content {
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: 400;
  font-size: 14px;
  color: #6B6B6B;
  line-height: 30px;
  text-align: left;
  font-style: normal;

  margin-top: 23px;
  width: 460px;
}
.middle-content-left-more-btn {
  width: 138px;
  height: 46px;

  margin-top: 30px;
}
.product_1 {
  position: relative;

  /*padding-bottom: 120px;*/
}
.middle-content-right {
  margin-left: 260px;
  margin-top: 78px;
  width: 1160px;
  height: 660px;
}
.middle-content-right-small {
  display: flex;

  position: absolute;
  top: 410px;
  left: 560px;
  width: 100%;
  height: 100%;
  z-index: 1; /* 设置z-index高于背景 */
}
.middle-content-right {
  position: relative;
}
.Homepage {
  height: 100vh; /* 根据需要调整 */
  overflow: hidden;
}

</style>
