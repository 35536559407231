import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  // 默认页面
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/CompanyView.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('../views/DetailView.vue')
  },
  {
    path: '/wx/profile',
    name: 'profile',
    component: () => import('../views/ProfileView.vue')
  },
  {
    path: '/wx/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/wx/clean',
    name: 'clean',
    component: () => import('../views/CleanView.vue')
  },
  {
    path: '/wx/support',
    name: 'support',
    component: () => import('../views/SupportView.vue')
  },
  {
    path: '/wx/recruit',
    name: 'recruit',
    component: () => import('../views/RecruitView.vue')
  },
  {
    path: '/wx/link',
    name: 'link',
    component: () => import('../views/LinkView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
